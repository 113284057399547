<template>
    <div id="responsive-player">
        <video id="video" 
            ref="miniVideoPlayer"
            width='100%' 
            height='100%'
            muted
            :poster="getPoster()"
            oncontextmenu="return false"
            >
        </video>
        <div id="play-btn" v-if="isPlayBackPause" @click="actPlayPause()">
            <img src="../../assets/icons/Play_withoutshadow.svg" />
        </div>
        <div id="volume-btn" @click="actVolume()">
            <img :src="require(`@/assets/icons/${ volumeMuted ? 'volume_mute.svg' : 'volume_un_mute.svg'}`)" />
        </div>
    </div>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
    events: ['playBackEndedListner'],
    props: ['playerPayload', 'isPlayTrailer'],
    data() {
        return {
            controls: false,
            streamUrl: null,
            isPlayBackPause: false,
            volumeMuted: true
        }
    },
    created() {
        eventBus.$on('actMiniPlayerPlayPause', (action) => {
            const video = this.$refs.miniVideoPlayer;
            if(action == 'pause') video.pause();
            if(action == 'play') video.play();
        });
    },
    mounted() {
       
        const video = this.$refs.miniVideoPlayer;
        let singleTrailer = this.playerPayload && this.playerPayload.trailer && this.playerPayload.trailer[0];
        singleTrailer && singleTrailer.filelist && singleTrailer.filelist.map((stream, i) => {
        
            if(singleTrailer.quality == stream.quality) {
                this.streamUrl = stream.filename;
            }
        });
        if (window.Hls && window.Hls.isSupported() && !this.isPlayTrailer) {
            //   var video = document.getElementById('video');
                var hls = new window.Hls();
                hls.loadSource(this.streamUrl);
                hls.attachMedia(video);
                video.play().then((succes) => {
                console.log("succes", succes);
                }).catch((err) => {console.log("kkk", err)});
        }else{
            if(this.isPlayTrailer) {
                video.src = this.streamUrl;
                video.play().then((succes) => {
                console.log("succes", succes);
                }).catch((err) => {console.log("kkk", err)});
            } else {
                alert("Cannot stream HLS, use another video source");
            }
        }
        // Event listeners related to video playback
        video.addEventListener('play', () => {
            // console.log('Video playback started');
            this.isPlayBackPause = false;
        });

        video.addEventListener('pause', () => {
            // console.log('Video playback paused');
            this.isPlayBackPause = true;
        });

        video.addEventListener('ended', () => {
            // console.log('Video playback ended');
            this.isPlayBackPause = true;
            this.$emit("playBackEndedListner", true);
            // video.load();
        });

        // Event listeners for buffering and loading
        // video.addEventListener('waiting', () => {
        // console.log('Video buffering, waiting for data');
        // });

        // video.addEventListener('canplay', () => {
        // console.log('Enough video data is available for playback');
        // });

        // video.addEventListener('canplaythrough', () => {
        // console.log('Video can be played through without buffering');
        // });

        // Event listeners for seeking
        // video.addEventListener('seeking', () => {
        // console.log('Video seeking in progress');
        // });

        // video.addEventListener('seeked', () => {
        // console.log('Video seeked to a new position');
        // });

        // Event listeners for volume and time changes
        // video.addEventListener('volumechange', () => {
        // console.log('Volume changed:', video.volume);
        // });

        // video.addEventListener('timeupdate', () => {
        // console.log('Current time:', video.currentTime);
        // });

        // Event listeners for errors
        // video.addEventListener('error', (event) => {
        // console.error('Video error:', event.message);
        // });

        video.addEventListener('click', (event) => {
        //  console.error('Video click:');
         if(!this.isPlayBackPause) {
            video.pause();
         } else {
            video.play();
         }
        });
    },
    methods: {  
        checkVideoType(url) {
            const extension = url.split('.').pop().toLowerCase();
            if (extension === 'mp4') {
                return 'MP4';
            } else if (extension === 'mpd') {
                return 'MPD (MPEG-DASH)';
            } else if(extension === 'm3u8'){
                return 'm3u8';
            } else {
                return 'Unknown';
            }
        },

        actPlayPause() {
            const video = this.$refs.miniVideoPlayer;
            video.play();
            this.isPlayBackPause = false;
        },

        actVolume() {
            const video = this.$refs.miniVideoPlayer;
            this.volumeMuted = !this.volumeMuted;
            if (video.muted === true) {    
                video.muted = false;
            } else {
                video.muted = true;
            }
            
        },

        getPoster() {
            let content = this.playerPayload;
            if (
                content &&
                content.poster &&
                content.poster.length &&
                !content.inwatchlist
            ) {
                let index = content.poster.findIndex((element) => {
                return element.postertype === "LANDSCAPE";
                });

                if (index > -1) {
                return content.poster[index].filelist[1].filename;
                }
            } else if (content.inwatchlist) {
                return content.poster.landscape;
            }
            },

    },
    components: {
        
    },
    beforeDestroy() {
        eventBus.$off('actMiniPlayerPlayPause');
    }
}
</script>

<style scoped lang="scss">
#responsive-player {
    position: relative;
    width: 100%;
    height: 100%;
    #play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 100ms all ease;
        cursor: pointer;
        img {
            opacity: 0.6;
        }:hover {
            opacity: 0.9;
        }
    }
    #video {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    #volume-btn {
       position: absolute;
       bottom: 23%;
       right: 2.5%; 
       z-index: 99;
       cursor: pointer;
       img {
        padding: 5px;
        border: 3px solid #FFFFFF;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        opacity: 0.6;
       }:hover {
        opacity: 0.8;
       }
    }
}
</style>